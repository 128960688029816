<template>
  <v-card v-if="isLoggedIn" fluid class="fill-height view account px-4 pb-12 mb-12">
    <TopBar>
      <template v-slot:title>{{ $t('account.account') }}</template>
    </TopBar>

    <v-card flat>

      <h5 class="pt-4">{{ $t('account.profile') }}</h5>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-text-field
            v-model="user.userName"
            :prepend-icon="mdiBadgeAccount"
            :label="$t('fields.username')"
            required
          />
          <v-row>
            <v-col>
              <v-text-field
                v-model="user.firstName"
                :prepend-icon="mdiAccount"
                :label="$t('fields.firstname')"
                data-test-id="firstNameField"
                required
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="user.lastName"
                :prepend-icon="mdiAccountGroup"
                :label="$t('fields.lastname')"
                required
              />
            </v-col>
          </v-row>
          <v-text-field
            v-model="user.email"
            :prepend-icon="mdiEmail"
            :label="$t('fields.email')"
            required
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          @click="save"
          data-test-id="saveButton"
        >
          {{ $t('buttons.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-4">
      <h5>{{ $t('account.settings') }}</h5>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <LocaleChanger />
          <v-switch
            v-model="user.options['hide-announcements']"
            inset
            data-test-id="hideAnnouncementsSwitch"
            :label="$t('buttons.hide-announcements')"
            @change="updateOptions"
          ></v-switch>
        </v-form>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mdiAccount, mdiEmail, mdiBadgeAccount, mdiAccountGroup } from '@mdi/js'
import LocaleChanger from '@/components/LocaleChanger'
import TopBar from '@/components/TopBar'

export default {
  metaInfo() {
    return {
      title: this.$t('account.account'),
      meta: [{
        property: 'og:url',
        content: window.location.href
      }, {
        property: 'og:title',
        content: this.$t('account.account')
      }, {
        property: 'og:description',
        content: this.$t('account.default-meta-description')
      }]
    }
  },

  components: {
    LocaleChanger,
    TopBar
  },

  data: () => ({
    valid: true,
    mdiAccountGroup,
    mdiBadgeAccount,
    mdiAccount,
    mdiEmail,
  }),

  computed: {
    ...mapGetters('users', ['user', 'isLoggedIn']),
  },

  methods: {
    ...mapActions('users', ['updateUser']),

    updateOptions() {
      this.updateUser({
        options: this.user.options
      })
    },

    save() {
      this.updateUser({
        userName: this.user.userName,
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
      });
    },
  },

};
</script>

<style>

</style>
