<template>
  <div class="locale-changer">
    <v-select
      v-model="lang"
      :prepend-icon="mdiTranslate"
      :label="$t('locale.language')"
      :items="langs"
      @change="setLang"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mdiTranslate } from '@mdi/js'
import i18n from '@/plugins/i18n'
import ISO6391 from 'iso-639-1'

export default {
  name: 'LocaleChanger',

  data: () => ({
    lang: '',
    langs: [],
    mdiTranslate
  }),

  computed: {
    ...mapGetters('users', ['locale'])
  },

  created() {
    this.langs = i18n.availableLocales.map((l) => ({ value: l, text: ISO6391.getName(l) }))
    this.lang = this.locale
  },

  methods: {
    ...mapActions('users', ['setLocale']),

    setLang() {
      this.setLocale(this.lang)
    }
  }
}
</script>

<style scoped>
</style>
